import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import comingSoon from './assets/coming-soon.png';

function App() {
  return (
    <div className="App background-image">
      <img
          src={comingSoon}
          alt="coming-soon"
          draggable="false"
          className="image-resizable-small"
      />
    </div>
  );
}

export default App;
